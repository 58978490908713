/* eslint-disable indent */
import { useCallback } from "react";

import { useSession } from "next-auth/react";
import { format, parseISO } from "date-fns";

import { useUserDataStore } from "@/store/UserDataStore";
import { useFetchVoucher } from "@/hooks/useFetchVoucher";

import { addDiscountCode, putCheckout, getCheckout } from "../API";
import { useFunnelStore } from "../store";
import {
	AllRecipeData,
	Checkout,
	Pet,
	Product,
	UpdateCheckout,
} from "../types";
import { track } from "../helpers";

export const useCheckout = () => {
	const { status } = useSession();

	const [setAPIError] = useFunnelStore((state) => [state.setAPIError]);

	const [user, checkout, setCheckout, updateUser, userExperiments] =
		useUserDataStore((state) => [
			state.user,
			state.checkout,
			state.setCheckout,
			state.updateUser,
			state.userExperiments,
		]);
	const { checkVoucher } = useFetchVoucher({ dontCheck: true });

	const getCompletedCheckout = useCallback(async (paymentIntent: string) => {
		return getCheckout(paymentIntent, { skip: !paymentIntent }, false);
	}, []);

	const updateCheckout = async (
		newCheckoutDetails: Checkout & UpdateCheckout,
		tracking?: { name: string; category: string },
		checkoutID?: string
	) => {
		console.log("putting checkout", newCheckoutDetails);
		const updatedCheckout = await putCheckout(
			newCheckoutDetails,
			user,
			userExperiments,
			checkoutID || checkout?.id,
			undefined,
			status === "authenticated" &&
				!user.sales_person_id &&
				!checkout?.sales_person_id
		);

		// console.log("update checkout response", updatedCheckout);

		// CHECK FOR ERRORS WHEN SAVING
		if (updatedCheckout && updatedCheckout.errorMsg) {
			setAPIError(updatedCheckout.errorMsg);
			window.location.href.includes("localhost") &&
				console.log(updatedCheckout.errorMsg);
			tracking &&
				track(tracking.name, {
					category: tracking.category,
					error: updatedCheckout.errorMsg,
				});

			return {
				error: updatedCheckout.errorMsg,
				data: null,
			};
		}

		return {
			error: null,
			data: updatedCheckout.data,
		};
	};

	const _checkIfItemExistsInCart = useCallback(
		(petId: string, newCheckout: Checkout) => {
			if (petId && newCheckout) {
				const foundIndex = newCheckout.lineItems?.findIndex((item) => {
					return item.pet && item.pet.name && item.pet.petId === petId;
				});

				return typeof foundIndex === "undefined" ? -1 : foundIndex;
			}

			return -1;
		},
		[]
	);

	const checkExistsAnyOneOff = useCallback(() => {
		if (checkout?.lineItems) {
			return checkout?.lineItems?.findIndex((item) => !item.pet);
		}

		return -1;
	}, [checkout?.lineItems]);

	// TODO work out what type item is
	const checkExistsOneOff = useCallback(
		(item: any, otherOneOffs: any) => {
			console.log(item, otherOneOffs);
			if (!checkout?.lineItems || otherOneOffs === -1) {
				return -1;
			}

			console.log(checkout?.lineItems[otherOneOffs].items);

			return checkout?.lineItems[otherOneOffs].items.findIndex(
				(i) => i.variant_id === item.variant_id
			);
		},
		[checkout?.lineItems]
	);

	const removeAllPets = useCallback((checkout: Checkout) => {
		const newLineItems = checkout.lineItems?.filter(
			(lineItem) => !lineItem.pet
		);

		return {
			...checkout,
			lineItems: newLineItems || [],
		};
	}, []);

	// TODO workout what type products is
	const addMultiToBasket = useCallback(
		async (
			products: { pet?: Pet; items: any[] }[],
			force_discount_code?: string
		) => {
			track("go to checkout", {
				"current checkout first pet name": checkout?.lineItems?.[0]?.pet?.name,
				"new checkout first pet name": products?.[0]?.pet?.name,
				"existing checkout with different first pet": !!(
					checkout?.lineItems?.[0]?.pet?.name &&
					checkout?.lineItems?.[0]?.pet?.name !== products?.[0]?.pet?.name
				),
			});

			let newCheckout = {
				...checkout,
				lineItems: [
					...(checkout?.lineItems
						? JSON.parse(JSON.stringify(checkout?.lineItems))
						: []),
				],
			};

			console.log("PRODUCTS", products);

			const newItems = [];

			for (const product of products) {
				if (product.pet) {
					// REMOVE ALL PETS FROM EXISTING CHECKOUT
					newCheckout = removeAllPets(newCheckout);
				} else {
					const otherOneOffs = checkExistsAnyOneOff();
					const existingProduct = checkExistsOneOff(
						product.items[0],
						otherOneOffs
					);
					if (existingProduct > -1 && otherOneOffs > -1) {
						newCheckout.lineItems[otherOneOffs].items[
							existingProduct
						].quantity += product.items[0].quantity;
						continue;
					}

					if (otherOneOffs > -1) {
						newCheckout.lineItems[otherOneOffs].items = newCheckout.lineItems[
							otherOneOffs
						].items.concat(product.items);
						continue;
					}
				}
				newItems.push(product);
			}

			const finalCheckout = {
				...newCheckout,
				force_discount_code: force_discount_code || undefined,
				lineItems: [...newCheckout.lineItems, ...newItems],
				use_terminal: false, // CHANGE TO !!user.sales_person_id WHEN WANTING DEFAULT ON
				use_new_terminal: false,
			};

			console.log("finalCheckout", finalCheckout);

			const calcCheckout = await putCheckout(
				finalCheckout,
				user,
				userExperiments
			);

			console.log("--- checkout putted ---");

			if (!calcCheckout || calcCheckout.errorMsg) {
				return {
					error: calcCheckout.errorMsg,
					data: null,
				};
			}

			return {
				error: null,
				data: calcCheckout.data,
			};
		},
		[
			checkExistsAnyOneOff,
			checkExistsOneOff,
			checkout,
			removeAllPets,
			user,
			userExperiments,
		]
	);

	const updateDiscountCode = useCallback(
		async (code?: string) => {
			const shouldRemoveCode = !code && checkout?.discount_code;

			if (shouldRemoveCode) {
				updateUser({ voucher: null });

				const newCheckout = {
					discount_code: null,
				};

				const updatedCheckout = await putCheckout(
					newCheckout,
					{
						...user,
						voucher: null,
					},
					userExperiments,
					checkout.id,
					false
				);

				// CHECK FOR ERRORS WHEN SAVING
				if (!updatedCheckout || updatedCheckout.errorMsg) {
					track("Form Validation", {
						category: "checkout",
						error: updatedCheckout.errorMsg,
					});

					return {
						error: updatedCheckout.errorMsg,
					};
				}

				track("Remove voucher code", {
					category: "checkout",
				});

				setCheckout(updatedCheckout.data);

				return {
					code: null,
					error: null,
				};
			}

			if (code && checkout && checkout.id) {
				const updatedCheckout = await addDiscountCode(checkout.id, code);

				setCheckout(updatedCheckout);

				// CHECK FOR ERRORS WHEN SAVING
				if (updatedCheckout.errors) {
					const error =
						updatedCheckout?.errors[0]?.validation?.discount_code?.[0];
					track("Form Validation", {
						category: "checkout",
						error,
					});

					return { error };
				}

				track("Add voucher code", {
					category: "checkout",
				});

				return {
					code:
						updatedCheckout?.discount?.current ||
						updatedCheckout?.discount_code,
					error: null,
				};
			}

			return {
				error: "An error occured, please try again.",
			};
		},
		[checkout, setCheckout, updateUser, user, userExperiments]
	);

	const prepareCheckout = useCallback(
		(pet: Pet, selectedRecipes: string[], allRecipes: AllRecipeData[]) => {
			const productVariants = [];

			track("Checkout Selected Recipes", {
				recipes: selectedRecipes,
			});

			// set amount of packs for transition
			const transitionPackCount = pet.selectedRecipes.length;

			// TRANSITION PACK PRICING
			const dayPrice =
				Math.round(
					((pet.basePuppyPrice || pet.basePrice) / pet.subLength) * 100
				) / 100;
			const price = dayPrice * pet.trialLength * 100;

			const items = selectedRecipes.map((recipeHandle, index) => {
				const product = allRecipes.filter((recipe) => {
					return recipe.handle === recipeHandle;
				})[0].product;

				const trialVariant = product.variants.filter((variant) => {
					const sizeOfTrial = pet.trialSize / transitionPackCount;
					const aimSize =
						sizeOfTrial === 1162.5
							? 1160
							: sizeOfTrial === 462.5
							? 465
							: sizeOfTrial;

					return parseInt(variant.title) === aimSize;
				})[0];

				const subscriptionVariant = product.variants.filter(
					(variant) =>
						parseInt(variant.title) ===
						(pet.planSize || pet.baseSize) / pet.selectedRecipes.length
				)[0];

				const thisPrice =
					transitionPackCount > 1 && index === 0
						? Math.ceil(Math.round(price) / transitionPackCount)
						: transitionPackCount > 1
						? Math.floor(Math.round(price) / transitionPackCount)
						: Math.ceil(price);

				const benefits = pet.benefits?.find(
					({ handle }) => handle === recipeHandle
				);

				const tags = pet.tags?.find(
					({ handle }) => handle === recipeHandle
				)?.displayTag;

				return {
					variant_id: trialVariant.id,
					subscription_variant_id: subscriptionVariant.id,
					price: thisPrice,
					benefits: benefits?.benefits || [],
					tags: tags ? [tags] : [],
				};
			});

			const treats =
				pet.treats && pet.treats.length
					? pet.treats
							.filter((treat) => treat.qty > 0)
							.map((treat) => ({
								variant_id: treat.variant_id,
								quantity: treat.qty,
								price: treat.price * 100,
							}))
					: [];

			productVariants.push({
				pet: pet,
				items: [...items, ...treats],
			});

			console.log(productVariants);

			return productVariants;
		},
		[]
	);

	const removeFromBasket = useCallback(
		async (itemID: number, variant_id?: string) => {
			const newCheckout = {
				...checkout,
				lineItems: [...(checkout?.lineItems || [])],
			};

			let newLineItems;

			if (variant_id) {
				newLineItems = checkout?.lineItems?.map((item) => {
					if (item.id === itemID) {
						return {
							...item,
							items: item.items.filter(
								(itemItem) => `${itemItem.variant_id}` !== `${variant_id}`
							),
						};
					} else {
						return item;
					}
				});
			} else {
				newLineItems = checkout?.lineItems?.filter(
					(item) => `${item.id}` !== `${itemID}`
				);
			}

			newCheckout.lineItems = newLineItems || [];

			return await putCheckout(
				newCheckout,
				user,
				userExperiments,
				undefined,
				false
			);
		},
		[checkout, user, userExperiments]
	);

	const updateBasket = useCallback(
		async (
			itemID: number,
			quantity: number,
			scoop: boolean,
			variant_id?: string
		) => {
			const newCheckout = {
				...checkout,
				lineItems: [...(checkout?.lineItems || [])],
			};

			const newLineItems = checkout?.lineItems?.map((item) => {
				if (item.id === itemID && quantity > 0) {
					return {
						...item,
						items: [
							{
								...(item.items.find(
									(i) => `${i.variant_id}` === `${variant_id}`
								) || item.items[0]),
								needs_scoops: scoop,
								quantity: quantity,
							} as Product,
							...(variant_id
								? item.items.filter(
										(i) => `${i.variant_id}` !== `${variant_id}`
								  )
								: item.items.slice(1)),
						],
					};
				} else {
					return {
						...item,
					};
				}
			});

			newCheckout.lineItems = newLineItems || [];

			console.log(newCheckout);

			const calcCheckout = await putCheckout(
				newCheckout,
				user,
				userExperiments,
				undefined,
				false
			);

			return calcCheckout;
		},
		[checkout, user, userExperiments]
	);

	const fetchCheckout = useCallback(
		async (id: string) => {
			const { error, data } = await getCheckout(id, {}, true);
			if (error !== null) {
				setAPIError(error?.[0]?.message);

				return error;
			}
			if (data?.discount_code) {
				await checkVoucher(data.discount_code, false, true);
			}
			updateUser({
				delivery_date: format(parseISO(data.delivery_date + ""), "yyyy-MM-dd"),
			});
			setCheckout(data);

			return data;
		},
		[checkVoucher, setAPIError, setCheckout, updateUser]
	);

	return {
		addMultiToBasket,
		prepareCheckout,
		removeFromBasket,
		updateBasket,
		updateCheckout,
		updateDiscountCode,
		fetchCheckout,
		getCompletedCheckout,
	};
};
