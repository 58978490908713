import { useEffect } from "react";

import { useRouter } from "next/router";
import { track } from "@amplitude/analytics-browser";
import { useSession } from "next-auth/react";

import { getSource } from "@/helpers/helperFunctions";
import { useFetchVoucher } from "@/hooks/useFetchVoucher";
import { useFunnelStore } from "@/store/FunnelStore";
import { useUserDataStore } from "@/store/UserDataStore";
import { useAccountDataStore } from "@/store/AccountDataStore";
import { useLoginHelper } from "@/hooks/useLoginHelper";

type Props = {
	noVoucher?: boolean;
	forceVoucher?: boolean;
	voucher?: string;
};

export const useLayout = ({ noVoucher, forceVoucher, voucher }: Props) => {

	const { showModal, method } = useFetchVoucher({
		dontCheck: noVoucher,
		forceVoucher,
		voucher,
	});

	const { data, status } = useSession();

	const [accountUser] = useAccountDataStore(state => [state.user])

	const { logOut } = useLoginHelper();

	const { query, } = useRouter();

	// Prevent the wrong customer data being shown.
	useEffect(() => {
		// if (data?.user.id && accountUser?.id && parseInt(`${data.user.id}`) !== parseInt(`${accountUser.id}`)) {
		// 	track("Mismatched customer ID", {
		// 		action: "log out",
		// 		sessionId: data.user.id,
		// 		storedId: accountUser.id
		// 	})
		// 	logOut();
		// }

		// if user has no token, sign them out
		if (accountUser && !accountUser.token && status === "authenticated") {
			track("Token missing", {
				action: "log out",
				sessionId: data.user.id,
				storedId: data.user.id
			})
			logOut()
		}
	}, [
		accountUser,
		accountUser?.id,
		data?.user.id,
		logOut,
		status
	])

	const [user, updateUser] = useUserDataStore((state) => [state.user, state.updateUser,]);

	const email = useFunnelStore((state) => state.email);

	if (showModal) {
		track("RAF view link", {
			category: "RAF",
			method: method,
		});
	}


	// Track unique site visits
	try {
		if (
			typeof window !== "undefined" &&
			window.sessionStorage !== null &&
			window.sessionStorage.getItem("unique_site_visit") !== "logged"
		) {
			window.sessionStorage.setItem("unique_site_visit", "logged");
			track("Site visit", {
				category: "",
				email: user?.email || email,
			});
		}
	} catch (e) {
		console.log(e);
	}

	useEffect(() => {
		if (query.awc && user.awc !== query.awc) {
			updateUser({ awc: query.awc as string });
		}
	}, [
		query.awc,
		updateUser,
		user.awc
	]);

	useEffect(() => {
		const source = user
			? getSource(user.source)
			: null;

		if (source !== user.source && source) {
			updateUser({ source: source });
		}
	}, [
		updateUser,
		user,
		voucher
	]);

	return {
		showModal,
		user,
	};
};
