import { track } from "@amplitude/analytics-browser";

import { initializeApollo } from "./apolloClient";
import { REFERRAL_QUERY, VOUCHER_QUERY } from "./queries";

import { Referral, Voucher } from "../types";

export const getVoucherData: (code: string) => Promise<Voucher | null> = async (
	code: string
) => {
	if (!code || code === null) {
		return null;
	}

	const apolloClient = initializeApollo();
	
	return await apolloClient
		.query({
			query: VOUCHER_QUERY,
			variables: { discount_code: code },
			context: { clientName: "subApp" },
		})
		.then((result: { data: { discount: Voucher } }) => {
			if (result.data.discount === null) {
				track("Voucher not found", { voucher: code });
				
				return null;
			}

			return {
				...result.data?.discount,
				start_date: new Date(result.data.discount.start_date),
				end_date: new Date(result.data.discount.end_date),
			};
		});
};

export const getReferralData = async (code: string): Promise<Referral> => {
	const apolloClient = initializeApollo();
	
	return await apolloClient
		.query({
			query: REFERRAL_QUERY,
			variables: { token: code },
			context: { clientName: "subApp" },
		})
		.then(
			(result: { data: { referralScheme: Referral } }): Referral => ({
				...result.data?.referralScheme,
				scheme: {
					...result.data.referralScheme.scheme,
					discountCode: {
						...result.data.referralScheme.scheme.discountCode,
						end_date: new Date(
							result.data.referralScheme.scheme.discountCode.end_date
						),
						start_date: new Date(
							result.data.referralScheme.scheme.discountCode.start_date
						),
					},
				},
			})
		);
};
