import {
	useEffect, useMemo, useRef 
} from "react";

import { formatDuration, isBefore } from "date-fns";

import { getReferralSchemes } from "../API";
import { useAccountDataStore } from "../store";

export const useRafPromotion = () => {

	const getSchemesTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
	const attempts = useRef(0);
	const isFetching = useRef(false);


	const [
		user,
		schemes,
		setSchemes,
	] = useAccountDataStore(state => [
		state.user,
		state.rafSchemes,
		state.setRafSchemes,
	])
  
	useEffect(() => {
		if (!!schemes || isFetching.current) {
			return;
		}

		const getSchemes = async () => {
			isFetching.current = true;

			if (getSchemesTimeout.current !== null) {
				clearTimeout(getSchemesTimeout.current);
			}

			if (!user || attempts.current > 10) {
				isFetching.current = false;
				
				return;
			}

			const customerSchemes = await getReferralSchemes(`${user?.id}`, false, `${user?.token}`);
			
			if (!customerSchemes) {
				attempts.current++;
				getSchemesTimeout.current = setTimeout(() => getSchemes(), 200);
				
				return;
			}

			setSchemes(customerSchemes);

			isFetching.current = false;
		};

		getSchemes();
	}, [
		setSchemes,
		user,
		schemes
	]);

	const isInitialPromotion = useMemo(() => {
		if (!schemes?.scheme.referralScheme?.endDate) {
			return false;
		}

		return isBefore(new Date(), schemes.scheme.referralScheme?.endDate)
	},[schemes?.scheme.referralScheme?.endDate])

	const endingTime = useMemo(() => {
		if (!schemes?.scheme.referralScheme?.timeToEnd) {
			return ""
		}

		return formatDuration(schemes.scheme.referralScheme.timeToEnd, { 
			format: [
				"days",
				"hours",
				"minutes"
			],
			zero: true,
			delimiter: " : " 
		}).replace("minutes", "mins").replace("hours", "hrs")
	}, [schemes?.scheme.referralScheme?.timeToEnd])

	return {
		schemes,
		isInitialPromotion,
		endingTime,
		currentScheme: schemes?.scheme
	}
}