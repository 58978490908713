import { create } from "zustand";
import { Reader } from "@stripe/terminal-js";

import { LineItem } from "../types";

type CheckoutStore = {
	voucherCode: string | null;
	setVoucherCode: (newCode: CheckoutStore["voucherCode"]) => void;

	apiError: string | null;
	setAPIError: (apiError: CheckoutStore["apiError"]) => void;

	country: string;
	setCountry: (country: CheckoutStore["country"]) => void;

	subscriptions: LineItem[];
	setSubscriptions: (subscriptions: CheckoutStore["subscriptions"]) => void;

	terms: boolean;
	setTerms: (terms: CheckoutStore["terms"]) => void;

	deliveryDate: Date | null;
	setDeliveryDate: (date: CheckoutStore["deliveryDate"]) => void;

	isLoading: boolean;
	setIsLoading: (isLoading: CheckoutStore["isLoading"]) => void;

	isLoggedIn: boolean;
	setIsLoggedIn: (loggedIn: boolean) => void;

	basketOpen: boolean;
	setBasketOpen: (basketOpen: boolean) => void;

	cardReaders: Reader[];
	setCardReaders: (reader: Reader) => void;
};

const cardReadersSessionKey = "card_readers";

const loadCardReadersFromSession = (): Reader[] => {
	const storedReaders = globalThis.sessionStorage?.getItem(
		cardReadersSessionKey
	);
	return storedReaders ? JSON.parse(storedReaders) : [];
};

export const useCheckoutStore = create<CheckoutStore>((set) => ({
	voucherCode: null,
	setVoucherCode: (newCode) => set(() => ({ voucherCode: newCode })),

	apiError: null,
	setAPIError: (apiError) => set(() => ({ apiError })),

	country: "United Kingdom",
	setCountry: (country) => set(() => ({ country })),

	subscriptions: [],
	setSubscriptions: (subscriptions) => set(() => ({ subscriptions })),

	terms: false,
	setTerms: (terms) => set(() => ({ terms })),

	deliveryDate: new Date(),
	setDeliveryDate: (deliveryDate) => set(() => ({ deliveryDate })),

	isLoading: false,
	setIsLoading: (isLoading) => set(() => ({ isLoading })),

	isLoggedIn: false,
	setIsLoggedIn: (isLoggedIn) => set(() => ({ isLoggedIn })),

	basketOpen: false,
	setBasketOpen: (basketOpen) => set(() => ({ basketOpen })),

	cardReaders: loadCardReadersFromSession(),
	setCardReaders: (reader) =>
		set((state) => {
			const filteredReaders = state.cardReaders.filter(
				(r) => r.id !== reader.id
			);

			const updatedReaders = [reader, ...filteredReaders].slice(0, 3); // Keep only the most recent 3 readers

			globalThis.sessionStorage?.setItem(
				cardReadersSessionKey,
				JSON.stringify(updatedReaders)
			);

			return { cardReaders: updatedReaders };
		}),
}));
